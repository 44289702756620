<cdk-virtual-scroll-viewport itemSize="48" tvsItemSize="48" headerHeight="56">
    <table mat-table [dataSource]="dataSource" [trackBy]="trackFn" matSort (matSortChange)="sortData($event)"
        matSortDisableClear [matSortActive]="sort.active" [matSortDirection]="sort.direction" class="mat-elevation-z8">

        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <!-- <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox> -->
            </th>
            <td mat-cell *matCellDef="let row">
                <!-- <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? select.toggle(row) : null"
                    [checked]="select.isSelected(row.$key)">
                </mat-checkbox> -->
                <!-- <mat-checkbox [checked]="select.isSelected(row.$key)" [disable]="true">
                </mat-checkbox> -->
                <mat-icon *ngIf="select.isSelected(row.$key)" color="accent" matListIcon>check_box</mat-icon>
                <mat-icon *ngIf="!select.isSelected(row.$key)" matListIcon>check_box_outline_blank</mat-icon>
            </td>
        </ng-container>

        <ng-container *ngFor="let col of item.tables[tableKey].columns" [matColumnDef]="col.field">
            <th mat-header-cell *matHeaderCellDef [mat-sort-header]="col.field">{{col.label}}
                <!-- <mat-select placeholder="Set remarks">
                    <mat-option value="report">Report</mat-option>
                    <mat-option value="pageConfig.edit">Edit</mat-option>
                    <mat-option value="delete">Delete</mat-option>
                  </mat-select> -->
            </th>
            <td mat-cell *matCellDef="let element">

                <!-- Normal Multi Display-->

                <ng-container *ngIf="col.options?.multi; else elseBlock">
                    <ng-container *ngFor="let e of element[col.field]">
                        <ng-container [ngSwitch]="col.type">
                            <ng-container *ngSwitchCase="'number'">
                                {{col.options?.prefix ? col.options.prefix : ''}}{{e | number}}{{col.options?.suffix ?
                                col.options.suffix : ''}}
                                <br />
                            </ng-container>
                            <ng-container *ngSwitchCase="'icon'">
                                <mat-icon matListIcon>{{e}}</mat-icon>
                            </ng-container>
                            <ng-container *ngSwitchCase="'fcn'">
                                {{col.fcn(e, item)}}
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                {{col.options?.prefix ? col.options.prefix : ''}}{{e}}{{col.options?.suffix ?
                                col.options.suffix : ''}}
                                <br />
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>


                <!-- Simple Display -->

                <ng-template #elseBlock>
                    <ng-container [ngSwitch]="col.type">
                        <ng-container *ngSwitchCase="'number'">
                            {{col.options?.prefix ? col.options.prefix : ''}}{{element[col.field] |
                            number}}{{col.options?.suffix ? col.options.suffix : ''}}
                        </ng-container>
                        <ng-container *ngSwitchCase="'icon'">
                            <mat-icon matListIcon [ngClass]="element[col.field + 'Color']">{{element[col.field]}}
                            </mat-icon>
                            <mat-icon matListIcon *ngIf="element.alerts?.length">{{GLOBAL.ICONS.ALERT}}</mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchCase="'user'">
                            <img *ngIf="element[col.field]" mat-card-avatar class="realtor"
                                [src]="element[col.field] | realtor" [matTooltip]="element[col.field] | realtorName" />
                        </ng-container>
                        <ng-container *ngSwitchCase="'ago'">
                            {{tools.timeago(element[col.field])}}
                        </ng-container>
                        <ng-container *ngSwitchCase="'date'">
                            {{element[col.field] ? (element[col.field].toDate() | date:'dd MMM yy HH:mm') : ''}}
                        </ng-container>
                        <ng-container *ngSwitchCase="'days'">
                            <ng-container *ngIf="tools.days(element[col.field]) as days; else noDays">
                                <span [ngClass]="tools.daysColor(days)">
                                    {{days | number}}
                                </span>
                            </ng-container>
                            <ng-template #noDays>
                                <span class="warm">Today</span>
                            </ng-template>
                        </ng-container>
                        <ng-container *ngSwitchCase="'score'">
                            <ng-container>
                                <ng-container *ngIf="element[col.field] !== null; else noDisplay">
                                    <span *ngIf="element[col.field] !== 0; else alertIcon" [ngClass]="tools.scoreColor(element[col.field])">
                                        {{element[col.field]}}%
                                    </span>
                                    <ng-template #alertIcon>
                                        <mat-icon color="warn" matListIcon>{{GLOBAL.ICONS.ALERT}}</mat-icon>
                                    </ng-template>
                                </ng-container>
                                <ng-template #noDisplay></ng-template>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'fcn'">
                            {{col.fcn(element, item)}}
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            {{col.options?.prefix ? col.options.prefix : ''}}{{element[col.field]}}{{col.options?.suffix
                            ? col.options.suffix : ''}}
                        </ng-container>
                    </ng-container>
                    <!-- <ng-container *ngIf="item.fields[col.key].input == 'select'">
                    {{setting.getParamValueName(item.fields[col.key].inputOptions.param, element[col.key])}}
                </ng-container> -->
                </ng-template>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="colsDisplay; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; let element; columns: colsDisplay" (click)="clickItem(element)"
            (mouseover)="overItem(element)" class="cursor"
            [ngClass]="(columnAlert && element[columnAlert]) ? 'bgAlert' : ''">
        </tr>
    </table>
</cdk-virtual-scroll-viewport>